@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base 
{
    :root {
      --chart-1: 12 76% 61%;
      --chart-2: 173 58% 39%;
      --chart-3: 197 37% 24%;
      --chart-4: 43 74% 66%;
      --chart-5: 27 87% 67%;
    }
  
    .dark {
      --chart-1: 220 70% 50%;
      --chart-2: 160 60% 45%;
      --chart-3: 30 80% 55%;
      --chart-4: 280 65% 60%;
      --chart-5: 340 75% 55%;
    }
}

svg:not(.svg-exclude-size) {
  width: 1em;
  height: 1em;
}

svg:not(.svg-exclude-fill)
{
  color: currentColor
}

svg.fill-current
{
  fill: currentColor
}


@layer base {
  /* For all inputs on focus */
  input:focus,
  textarea:focus,
  select:focus {
    @apply ring-2 ring-maven-primary-500 border-maven-primary-500 outline-none;
  }

  /* For buttons & links only on keyboard focus */
  button:focus-visible,
  a:focus-visible {
    @apply outline-none ring-2 ring-maven-primary-500;
  }
}